:root {
  --light-gray: #84828F;
  --green: #9CC5A1;
  --purple: #381D2A;
  --pink: #FDD4C4;
  --dark-gray: #3E4C5E;
}

@font-face {
  font-family: 'Cabin';
  src: url('./../public/Cabin/Cabin-VariableFont_wdth\,wght.ttf') format('truetype')
}

body {
  margin: 0;
  background-color: var(--light-gray);
  scroll-behavior: smooth;
}

body *, html {
  margin: 0;
  font-family: Cabin;
  color: var(--pink);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth
}

a {
  text-decoration: none;
}

.parent-div {
  position: absolute; 
}

.banner {
  padding-top: 30px;
  padding-bottom: 60px;
  background-color: var(--dark-gray);
}

.banner h3 {
  width: fit-content;
  margin: 0 auto;
  margin-top: 40px;
}

.banner > p {
  width: 70vw;
  max-width: 579px;
  margin: 0 auto;
  margin-top: 45px;
  font-size: 16px;
  text-align: center;
}

section:not(.banner) {
    /* padding-top: 30px; */
    min-height: 350px;
}

section:not(.banner) > h1 {
    margin-top: 30px;
    margin-bottom: 30px;
}

section:not(.banner) > a {
  position:relative;
  top: -70px;
}

/* section:not(.banner):nth-child(odd) {
  background-color: var(--dark-gray);
} */

h1 {
  width: fit-content;
  margin: 0 auto;
  font-size: 36px;
}

.banner > h1 {
  margin-top: 70px;
  font-size: 42px;
}

.navbar {
  position: sticky;
  height: 0px;
  display: flex;
  top: 30px;
}

.navbar > ul {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 0px;
  backdrop-filter: blur(5px);
  position: relative;
  border-radius: 5px;
}

.navbar > ul > div.navbar-slider {
  position: absolute;
  left: 0%;
  width: 101px;
  height: 100%;
  background-color: var(--pink); 
  z-index: -1;
  border-radius: 0px;
  transition: all 0.4s ease;
}

.navbar > ul > div.navbar-slider.first {
  border-radius: 5px 0px 0px 5px;
  left: 0%;
}

.navbar > ul > div.navbar-slider.two {
  left: 20%;
}

.navbar > ul > div.navbar-slider.three {
  left: 40%;
}

.navbar > ul > div.navbar-slider.four {
  left: 60%;
}

.navbar > ul > div.navbar-slider.last {
  left: 80%;
  border-radius: 0px 5px 5px 0px;
}

ul.links {
  margin: 0 auto;
  margin-top: 10px;
  width: fit-content;
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 0px;
  column-gap: 8px;
}

ul.links > li > a > svg {
  font-size: 32px;
}

ul.links > li > a {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  align-items: center;
}

.navbar > ul > a {
  width: 90px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid var(--purple);
  padding: 5px;
}

.navbar > ul > a.active > li, .navbar > ul > a:hover > li {
  color: var(--purple);
}

.navbar > ul > a:first-of-type {
  border-radius: 5px 0px 0px 5px;
}

.navbar > ul > a:last-child {
  border-radius: 0px 5px 5px 0px;
}

.navbar > ul > a:not(:last-child) {
  border-right: none;
}

ul.links svg {
  padding: 5px;
}

ul.links > li {
  padding: 5px;
}

/* ul.links li:hover svg, */
ul.links li:hover svg, ul.links li:hover {
  background-color: var(--pink);
  border-color:transparent;
  color: var(--purple);
  border-radius: 5px;
}

ul.links li:hover svg > path, ul.links li:hover > a {
  color: var(--purple);
}

.projects-div {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 20px;
  max-width: 1400px;
}

.projects-div .top-projects, .projects-div .bottom-projects {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.projects-div .bottom-projects {
  display: flex;
}

.projects-div .project-tile {
  width: 45%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid black;
}

.projects-div .project-tile > h2 {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 20px;
}

.projects-div .project-tile .project-media {
  display: none;
  width: 320px;
  height: 320px;
  min-width: 320px;
  min-height: 320px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: var(--light-gray);
}

.projects-div .top-half-tile {
  display: flex;
  column-gap: 20px;
  justify-content: space-around;
}

.project-div {
  min-width: 120px;
}

.project-stack {
  max-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-stack > p {
  width: fit-content;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
}

.project-stack > ul {
  list-style-type: none;
  display: flex;
  column-gap: 10px;
  row-gap: 15px;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.project-stack > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-div .project-info {
  margin: 0 auto;
}

.projects-div .link-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid black;
  width: 130px;
  /* width: 45%; */
  height: 40px;
  margin: 0 auto;
  margin-top: 20px;
}

.projects-div .link-div:hover, .link-div:hover * {
  background-color: var(--pink);
  border-color:transparent;
  color: var(--purple);
  border-radius: 5px;
}

.projects-div .link-div span {
  margin-right: 5px;
}

.projects-div .link-div:hover span {
  font-size: 17px;
}

.projects-div .link-div svg {
  /* font-size: 32px; */
}

.projects-div .project-tech {
  /* flex: 1 1 auto; */
}

.project-tile > p {
  margin-top: 20px;
}

.skills-div {
  max-width: 1440px;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.skills-div > p {
  width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
}

.skills-div > ul {
  list-style-type: none;
  display: flex;
  column-gap: 20px;
  row-gap: 30px;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.skills-div > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.skills-div span {
  margin-top: 5px;
}


@media screen and (max-width: 1440px) {
  section:not(.banner) {
    padding: 20px;
  }
}

@media screen and (max-width: 1230px) {
  .projects-div .top-half-tile {
    flex-direction: column;
  }

  .projects-div .project-tile .project-media {
    flex: 1 1 auto;
    width: auto;
    height: auto;
    /* min-width: 320px;
    min-height: 320px; */
    border-radius: 5px;
    border: 1px solid black;
    background-color: var(--light-gray);
  }

  .project-tile .project-details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 20px;
  }

  .project-details .project-links .link-div {
    /* padding: 0px 65px; */
  }
}

@media screen and (max-width: 800px) {
  .projects-div .top-projects, .projects-div .bottom-projects {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 0px;
    row-gap: 20px;
    align-items: center;
  }

  .projects-div .project-tile {
    width: 90%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid black;
  }

  .project-tile .project-links {
    display: flex;
    flex-direction: row;
  }

  .projects-div .top-half-tile {
    flex-direction: row;
  }

  .projects-div .project-tile .project-media {
    width: 320px;
    height: 320px;
    min-width: 320px;
    min-height: 320px;
  }

  .project-tile .project-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    column-gap: 20px;
  }

  .project-tile .project-links {
    flex-direction: column;
  }

  .project-tile .project-links > a.link-div {
    padding: 0;
    width: 90%;
  }

  .banner > p {
    width: 90vw;
  }
}

@media screen and (max-width: 560px) {
  .projects-div .top-half-tile {
    flex-direction: column;
  }

  .projects-div div.navbar {
    scale: 0.9;
  }

  .projects-div .project-tile .project-media {
    flex: 1 1 auto;
    width: auto;
    height: auto;
    /* min-width: 320px;
    min-height: 320px; */
    border-radius: 5px;
    border: 1px solid black;
    background-color: var(--light-gray);
  }

  .project-tile .project-details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 20px;
  }

  .project-details .project-links {
    width: 80%;
  }

  .project-details .project-links .link-div {
    /* padding: 0px 65px; */
    max-width: unset;
  }

  .project-stack > ul {
    row-gap: 10px;
  }

  .banner > p { 
    width: fit-content;
    padding: 0px 30px;
  }
}